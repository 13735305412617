exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-collabs-tsx": () => import("./../../../src/pages/collabs.tsx" /* webpackChunkName: "component---src-pages-collabs-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lab-any-case-tsx": () => import("./../../../src/pages/lab/any-case.tsx" /* webpackChunkName: "component---src-pages-lab-any-case-tsx" */),
  "component---src-pages-lab-blackjack-tsx": () => import("./../../../src/pages/lab/blackjack.tsx" /* webpackChunkName: "component---src-pages-lab-blackjack-tsx" */),
  "component---src-pages-lab-calendar-tsx": () => import("./../../../src/pages/lab/calendar.tsx" /* webpackChunkName: "component---src-pages-lab-calendar-tsx" */),
  "component---src-pages-lab-carousel-tsx": () => import("./../../../src/pages/lab/carousel.tsx" /* webpackChunkName: "component---src-pages-lab-carousel-tsx" */),
  "component---src-pages-lab-collapsible-tsx": () => import("./../../../src/pages/lab/collapsible.tsx" /* webpackChunkName: "component---src-pages-lab-collapsible-tsx" */),
  "component---src-pages-lab-count-on-tsx": () => import("./../../../src/pages/lab/count-on.tsx" /* webpackChunkName: "component---src-pages-lab-count-on-tsx" */),
  "component---src-pages-lab-czerman-tsx": () => import("./../../../src/pages/lab/czerman.tsx" /* webpackChunkName: "component---src-pages-lab-czerman-tsx" */),
  "component---src-pages-lab-drag-drop-tsx": () => import("./../../../src/pages/lab/drag-drop.tsx" /* webpackChunkName: "component---src-pages-lab-drag-drop-tsx" */),
  "component---src-pages-lab-grid-tsx": () => import("./../../../src/pages/lab/grid.tsx" /* webpackChunkName: "component---src-pages-lab-grid-tsx" */),
  "component---src-pages-lab-hexapawn-tsx": () => import("./../../../src/pages/lab/hexapawn.tsx" /* webpackChunkName: "component---src-pages-lab-hexapawn-tsx" */),
  "component---src-pages-lab-index-tsx": () => import("./../../../src/pages/lab/index.tsx" /* webpackChunkName: "component---src-pages-lab-index-tsx" */),
  "component---src-pages-lab-inputs-tsx": () => import("./../../../src/pages/lab/inputs.tsx" /* webpackChunkName: "component---src-pages-lab-inputs-tsx" */),
  "component---src-pages-lab-invaders-tsx": () => import("./../../../src/pages/lab/invaders.tsx" /* webpackChunkName: "component---src-pages-lab-invaders-tsx" */),
  "component---src-pages-lab-lightbox-tsx": () => import("./../../../src/pages/lab/lightbox.tsx" /* webpackChunkName: "component---src-pages-lab-lightbox-tsx" */),
  "component---src-pages-lab-masonry-tsx": () => import("./../../../src/pages/lab/masonry.tsx" /* webpackChunkName: "component---src-pages-lab-masonry-tsx" */),
  "component---src-pages-lab-maze-tsx": () => import("./../../../src/pages/lab/maze.tsx" /* webpackChunkName: "component---src-pages-lab-maze-tsx" */),
  "component---src-pages-lab-passworld-tsx": () => import("./../../../src/pages/lab/passworld.tsx" /* webpackChunkName: "component---src-pages-lab-passworld-tsx" */),
  "component---src-pages-lab-scoreboard-tsx": () => import("./../../../src/pages/lab/scoreboard.tsx" /* webpackChunkName: "component---src-pages-lab-scoreboard-tsx" */),
  "component---src-pages-lab-scroll-animations-tsx": () => import("./../../../src/pages/lab/scroll-animations.tsx" /* webpackChunkName: "component---src-pages-lab-scroll-animations-tsx" */),
  "component---src-pages-lab-serp-tsx": () => import("./../../../src/pages/lab/serp.tsx" /* webpackChunkName: "component---src-pages-lab-serp-tsx" */),
  "component---src-pages-lab-snake-tsx": () => import("./../../../src/pages/lab/snake.tsx" /* webpackChunkName: "component---src-pages-lab-snake-tsx" */),
  "component---src-pages-lab-tabs-tsx": () => import("./../../../src/pages/lab/tabs.tsx" /* webpackChunkName: "component---src-pages-lab-tabs-tsx" */),
  "component---src-pages-lab-writest-tsx": () => import("./../../../src/pages/lab/writest.tsx" /* webpackChunkName: "component---src-pages-lab-writest-tsx" */),
  "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-how-to-set-up-import-aliases-for-gatsby-js-mdx": () => import("./../../../src/pages/{mdx.frontmatter__category}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/how-to-set-up-import-aliases-for-gatsby-js.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-how-to-set-up-import-aliases-for-gatsby-js-mdx" */),
  "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-imprint-mdx": () => import("./../../../src/pages/{mdx.frontmatter__category}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/imprint.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-imprint-mdx" */),
  "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-privacy-policy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__category}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-privacy-policy-mdx" */),
  "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-recorded-keynotes-talks-mdx": () => import("./../../../src/pages/{mdx.frontmatter__category}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/recorded-keynotes-talks.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-recorded-keynotes-talks-mdx" */),
  "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-terms-and-conditions-mdx": () => import("./../../../src/pages/{mdx.frontmatter__category}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-terms-and-conditions-mdx" */),
  "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-your-grid-layout-doesnt-have-to-be-boring-mdx": () => import("./../../../src/pages/{mdx.frontmatter__category}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/posts/your-grid-layout-doesn't-have-to-be-boring.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-category-mdx-frontmatter-slug-tsx-content-file-path-src-posts-your-grid-layout-doesnt-have-to-be-boring-mdx" */)
}

